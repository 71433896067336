<template>
  <column-layout class="page settings-page" sticky-sides noRight>
    <!-- Left -->
    <template v-slot:left>
      <Menu />
    </template>

    <!-- Main -->
    <template>
      <div class="widget">
        <label class="label">{{ $t("common.settings") }}</label>

        <div class="card pa">
          <!-- Header -->
          <div class="settings-header mb-7 tab-buttons flex-column flex-md-row">
            <v-btn
              v-for="tab in tabs"
              :key="tab.name"
              :color="currentTab === tab.tab ? 'primary' : 'secondary'"
              rounded
              :to="tab.to"
              class="tab-button"
            >
              <v-icon left>{{ tab.icon }}</v-icon>
              {{ tab.name }}
            </v-btn>
          </div>

          <!-- Tab -->
          <component class="settings-tab" :is="$route.meta.tab" />
        </div>
      </div>
    </template>
  </column-layout>
</template>

<script>
import Menu from "@/components/app/Menu.vue";

export default {
  metaInfo: { title: "Settings" },

  data() {
    return {
      tabs: [
        {
          icon: "mdi-account",
          name: this.$t("settings.general"),
          tab: "SettingsGeneral",
          to: { name: "app.settings" },
        },
        {
          icon: "mdi-cog",
          name: this.$t("settings.preferences"),
          tab: "SettingsPreferences",
          to: { name: "app.settings.preferences" },
        },
        {
          icon: "mdi-shield-alert",
          name: this.$t("settings.privacy"),
          tab: "SettingsPrivacy",
          to: { name: "app.settings.privacy" },
        },
      ],
    };
  },

  computed: {
    currentTab() {
      return this.$route.meta.tab;
    },

    isGeneral() {
      return this.currentTab === "SettingsGeneral";
    },

    isPreferences() {
      return this.currentTab === "SettingsPreferences";
    },

    isPrivacy() {
      return this.currentTab === "SettingsPrivacy";
    },
  },

  components: {
    Menu,
    SettingsGeneral: () =>
      import("@/components/app/settings/SettingsGeneral.vue"),
    SettingsPreferences: () =>
      import("@/components/app/settings/SettingsPreferences.vue"),
    SettingsPrivacy: () =>
      import("@/components/app/settings/SettingsPrivacy.vue"),
  },
};
</script>

<style lang="scss">
.settings-page {
  .form-input {
    display: flex;
    align-items: center;

    label {
      margin-right: 20px;
    }
  }
}
</style>
